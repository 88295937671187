import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import next from './next_arrow.svg';
import previous from './previous_arrow.svg';

const Calendar = () => {
    //day
    const dayjs = require('dayjs');
    const weekday = require('dayjs/plugin/weekday');
    const isoWeek = require('dayjs/plugin/isoWeek');
    const weekOfYear = require('dayjs/plugin/weekOfYear');

    // day extend
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);
    dayjs.extend(weekOfYear);

    const today = dayjs('2024-09-01');
    const [viewDate, setViewDate] = useState(today);
    const [selectDate, setSelectDate] = useState(dayjs());

    const createCalendar = () => {
        const startWeek = viewDate.startOf('month').week();
        const endWeek = viewDate.endOf('month').week() === 1 ? 53 : viewDate.endOf('month').week();
        let calender = [];

        for (let week = startWeek; week <= endWeek; week++) {
            calender.push(
                <div className="rows" key={week}>
                    {Array(7)
                        .fill(0)
                        .map((n, i) => {
                            let current = viewDate
                                .startOf('week')
                                .week(week)
                                .add(n + i, 'day');
                            if (viewDate.format('MM') === '12') {
                                current = viewDate
                                    .startOf('week')
                                    .week(week - 52)
                                    .add(n + i, 'day');
                            }
                            // 현재 날짜 (기준)
                            let isSelected =
                                selectDate.format('YYYYMMDD') === current.format('YYYYMMDD') ? 'selected' : '';
                            let isToday = today.format('YYYYMMDD') === current.format('YYYYMMDD') ? 'today' : '';
                            let isNone = current.format('MM') === viewDate.format('MM') ? '' : 'none';
                            return (
                                <Fragment key={`${week}_${i}`}>
                                    <div className={`box-calendar`}>
                                        <div
                                            className={`text-calendar ${isSelected} ${isToday} ${isNone}`}
                                            // onClick={() => {
                                            //   setSelectDate(current);
                                            // }}
                                        >
                                            <span className={`day`}>{current.format('D')}</span>
                                            {isToday ? (
                                                <span className="isToday"></span>
                                            ) : isSelected ? (
                                                <span className="isSelected"></span>
                                            ) : null}
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                </div>
            );
        }
        return calender;
    };

    const changegeMonth = (date, changeString) => {
        switch (changeString) {
            case 'add':
                return setViewDate(viewDate.add(1, 'month'));
            case 'subtract':
                return setViewDate(viewDate.subtract(1, 'month'));
            default:
                return date;
        }
    };

    return (
        <>
            <StyledHeader></StyledHeader>
            <StyledBody>
                <div className="rows week">
                    <div className="box-calendar">
                        <span className="text-calendar">일</span>
                    </div>
                    <div className="box-calendar">
                        <span className="text-calendar">월</span>
                    </div>
                    <div className="box-calendar">
                        <span className="text-calendar">화</span>
                    </div>
                    <div className="box-calendar">
                        <span className="text-calendar">수</span>
                    </div>
                    <div className="box-calendar">
                        <span className="text-calendar">목</span>
                    </div>
                    <div className="box-calendar">
                        <span className="text-calendar">금</span>
                    </div>
                    <div className="box-calendar">
                        <span className="text-calendar">토</span>
                    </div>
                </div>
                <div>{createCalendar()}</div>
            </StyledBody>
        </>
    );
};

export default Calendar;

const StyledHeader = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 20px;

    .thisMonth {
        font-weight: 700;
        color: #292929;
        line-height: 24px;
    }
    button {
        width: 24px;
        margin: 0 8px;
    }
    .previous_icon {
        width: 24px;
        height: 24px;
        background: url(${previous}) center no-repeat;
    }
    .next_icon {
        width: 24px;
        height: 24px;
        background: url(${next}) center no-repeat;
    }
`;

const StyledBody = styled.div`
    text-align: center;
    margin: 20px;

    .rows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 400px) {
            width: 100%;
        }
    }

    .rows.week {
        height: 18px;
        border-bottom: 1px solid #e8e8e8;

        @media screen and (max-width: 400px) {
            width: 100%;
            height: 18px;
        }
    }
    .box-calendar {
        width: 32px;
        height: 32px;
        margin: 4px;
        font-size: 14px;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 32px;
            margin: 2.5px;
        }
    }
    .text-calendar {
        position: static;
        width: 32px;
        height: 32px;
        color: #292929;

        @media screen and(max-width: 400px) {
            width: 30px;
            height: 30px;
        }
    }
    .holiday,
    .grayed {
        color: #484848;
        pointer-events: none;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px;
        }
    }
    .day {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px;
        }
    }
    .selected {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #8faf65;
        font-weight: 700;
        color: #fff;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px;
        }
    }
    .today {
        border-radius: 50%;
        font-weight: 500;
        /* color: pink; */
        background: #edf4e3;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px;
        }
    }
    .isSelected {
        position: relative;
        color: pink;
        font-size: 10px;
        font-weight: 400;

        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px;
        }
    }
    .isToday {
        position: relative;
        color: purple;
        font-size: 7px;
        top: -7px;
        white-space: nowrap;
    }
    .none {
        display: none;
    }
`;
