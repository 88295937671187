import React, { useEffect } from "react";
import styled from "styled-components";
import phone from "./phonecall.svg";
import close from "./close.svg";

const StyledModal = styled.div`
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(226, 239, 217, 0.3);
    transform: translate(-50%, -50%);
    color: black;
    backdrop-filter: blur(5px);
  }

  .modal-box {
    max-width: 300px;
    margin: 0 auto;
  }

  .modal-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0 50px;
  }

  .modal-text-en {
    font-size: 0.6rem;
    letter-spacing: 0.3rem;
    display: inline-block;

    @media screen and (max-width: 400px) {
      font-size: 0.5rem;
    }
  }

  .modal-text {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    display: inline-block;
    margin-right: 10px;

    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  .modal-text-width {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    display: inline-block;
    margin-right: 10px;
    width: 110px;

    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  .modal-text-name {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    display: inline-block;

    @media screen and (max-width: 400px) {
      font-size: 0.9rem;
    }

  }

  .modal-title {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;

    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }

  }

  .modal-body {
  }

  .modal-content-sub {
    font-size: 1rem;
    border-bottom: 1px dotted black;
    padding-bottom: 10px;

    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }

  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px 0;

    @media screen and (max-width: 400px) {
      padding: 10px 0;
    }

  }

  .modal-content-box {
    margin-bottom: 50px;

    @media screen and (max-width: 400px) {
      margin-bottom: 30px;
    }
  }

  .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
  }
.close-img {
    width: 40px;
    cursor: pointer;
  
    @media screen and (max-width: 400px) {
      width: 30px;
    }
  }
}
`;


export const Modal = ({ modal, setModal }) => {
  const closeModal = () => {
    setModal(false);
  };

  // 뒤로가기 방지
  useEffect(() => {
    if (!modal) return;
    window.history.pushState(null, null, null);
    window.onpopstate = () => {
      setModal(false);
    };
  }, [modal]);

  if (!modal) return null;

  return (
    <StyledModal>
      <div className="modal-container">
        <div className="modal-box">
          <div className="modal-header">
            <div className="modal-text-en">CONTACT</div>
            <div className="modal-title">연락하기</div>
          </div>
          <div className="modal-body">
            <div className="modal-content-box">
              <div className="modal-content-sub">
                <div className="modal-text">신랑측</div>
                <div className="modal-text-en">GROOM</div>
              </div>
              <div className="modal-content">
                <div className="modal-text-width">신랑</div>
                <div className="modal-text-name">강한별</div>
                <div className="copy-image">
                  <a href={"tel:01041439518"}>
                    <img width={20}  src={phone} alt="폰" />
                  </a>
                </div>
              </div>
              <div className="modal-content">
                <div className="modal-text-width">신랑 아버지</div>
                <div className="modal-text-name">강석헌</div>
                <div className="copy-image">
                  {/* <a href={"tel:"}>
                    <img width={20}  src={phone} alt="폰" />
                  </a> */}
                </div>
              </div>
              <div className="modal-content">
                <div className="modal-text-width">신랑 어머니</div>
                <div className="modal-text-name">김문점</div>
                <div className="copy-image">
                  {/* <a href={"tel:01094989944"}>
                    <img width={20}  src={phone} alt="폰" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="modal-content-box">
              <div className="modal-content-sub">
                <div className="modal-text">신부측</div>
                <div className="modal-text-en">BRIDE</div>
              </div>
              <div className="modal-content">
                <div className="modal-text-width">신부</div>
                <div className="modal-text-name">현지은</div>
                <div className="copy-image">
                  <a href={"tel:01032348270"}>
                    <img width={20} src={phone} alt="폰" />
                  </a>
                </div>
              </div>
              <div className="modal-content">
                <div className="modal-text-width">신부 아버지</div>
                <div className="modal-text-name">현철원</div>
                <div className="copy-image">
                  <a href={"tel:01099113932"}>
                    <img  width={20} src={phone} alt="폰" />
                  </a>
                </div>
              </div>
              <div className="modal-content">
                <div className="modal-text-width">신부 어머니</div>
                <div className="modal-text-name">이춘미</div>
                <div className="copy-image">
                  <a href={"tel:01028813932"}>
                    <img width={20}  src={phone} alt="폰" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-close-btn" onClick={closeModal}>
          <img className="close-img" src={close} />
        </div>
      </div>
    </StyledModal>
  );
};
