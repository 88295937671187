import './link.css';

const link = () => {
    const openNewTap = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="wedding-link">
            <div className="kakao" onClick={() => openNewTap('https://kko.to/Bw9hT3MQCj')}>
                <img className="kakaoimg" src={process.env.PUBLIC_URL + '/images/kakaonavi.svg'} />
                카카오
            </div>
            <div className="naver" onClick={() => openNewTap('https://naver.me/FiLOF48l')}>
                <img className="naverimg" src={process.env.PUBLIC_URL + '/images/naver.webp'} />
                네이버
            </div>
            <div className="tmap" onClick={() => openNewTap('https://tmap.life/c46557d7')}>
                <img className="tmapimg" src={process.env.PUBLIC_URL + '/images/tmap.svg'} />
                티맵
            </div>
        </div>
    );
};

export default link;
