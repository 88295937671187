import 'animate.css';
import React, { useEffect, useState } from 'react';
import './App.css';
import Calendar from './components/Calendar';
import Kakao from './components/Kakao';
import Link from './components/Link';
import Accordion from './components/Accordion';
import KakaoButton from './components/KakaoShare';
import { Modal } from './components/Modal';
import { MyGallery } from './components/Gallery';
import close from './components/close.svg';
import axios from 'axios';
import { getTimeAgo } from './components/getTimeAgo';
import Swal from 'sweetalert2';

function App() {
    const dueDate = new Date('2024-09-01T14:00:00');
    const [modal, setModal] = useState(false);
    const [guestBooks, setGuestBooks] = useState([]);
    const [guestBookInput, setGuestBookInput] = useState({
        nickname: '',
        content: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGuestBookInput({
            ...guestBookInput,
            [name]: value,
        });
    };

    const [remainTime, setRemainTime] = useState({
        day: 0,
    });

    const openModal = () => {
        setModal(true);
    };

    // disable zoom
    useEffect(() => {
        const disablePinchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };
        document.addEventListener('touchmove', disablePinchZoom, {
            passive: false,
        });
    }, []);

    useEffect(() => {
        const now = new Date();
        const gap = dueDate.getTime() - now.getTime();
        const day = Math.floor(gap / (1000 * 60 * 60 * 24));
        setRemainTime({ day });
    }, []);

    const io = new IntersectionObserver((entires) => {
        entires.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate__animated', 'animate__fadeInUp', 'animate__slow');
            }
        });
    });

    useEffect(() => {
        const targets = document.querySelectorAll('.animate-element');
        targets.forEach((target) => {
            io.observe(target);
        });
    }, []);

    const getGuestBooks = () => {
        axios.get('https://hb-je-weddingday.dev-arthur.com/_api/guest-book').then((res) => {
            setGuestBooks(res.data);
        });
    };

    useEffect(() => {
        getGuestBooks();
    }, []);

    const addGuestBook = () => {
        if (!guestBookInput.nickname || !guestBookInput.content || !guestBookInput.password) {
            Swal.fire({
                title: '모든 항목을 입력해주세요.',
                icon: 'error',
                confirmButtonColor: 'var(--primary-color)',
                width: '300px',
                background: '#f5f5f5',
                customClass: {
                    title: 'swal-title',
                    input: 'swal-input',
                    confirmButton: 'swal-button',
                    cancelButton: 'swal-button',
                },
            });
            return;
        }
        axios
            .post('https://hb-je-weddingday.dev-arthur.com/_api/guest-book', {
                nickname: guestBookInput.nickname,
                content: guestBookInput.content,
                password: guestBookInput.password,
            })
            .then(() => {
                getGuestBooks();
                setGuestBookInput({
                    nickname: '',
                    content: '',
                    password: '',
                });
            });
    };

    const deleteGuestBook = (id) => {
        Swal.fire({
            title: '비밀번호를 입력해주세요.',
            input: 'password',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off',
            },
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showLoaderOnConfirm: true,
            width: '300px',
            background: '#f5f5f5',
            confirmButtonColor: 'var(--primary-color)',
            customClass: {
                title: 'swal-title',
                input: 'swal-input',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button',
            },
            preConfirm: (password) => {
                return axios
                    .delete(`https://hb-je-weddingday.dev-arthur.com/_api/guest-book`, {
                        data: {
                            id,
                            password,
                        },
                    })
                    .then((res) => {
                        if (res.status !== 200) {
                            throw new Error(res.statusText);
                        }
                        getGuestBooks();
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`비밀번호가 일치하지 않습니다.`);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
    };

    return (
        <div className="main">
            {/* 헤더 */}
            <div className="header animate-element">
                <div className="groom">강한별</div>
                <div className="date">
                    <div className="month">09</div>
                    <div className="divider">
                        <div className="line"></div>
                    </div>
                    <div className="day">01</div>
                </div>
                <div className="bride">현지은</div>
            </div>
            <div className="wedding-date animate-element">SUN PM 2:00</div>
            {/* 메인사진 */}
            <div className="main-wedding-photo animate-element">
                <div
                    className="wedding-photo"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/img_1.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                ></div>
            </div>
            {/* 결혼식 정보 */}
            {/* <div className="wedding-info animate-element">
                <div> 2024년 09월 01일 일요일 오후 2시</div>
                <div> 디엘 웨딩홀 3층 마이아홀 </div>
            </div> */}
            {/* 초대글귀 */}
            <div className="wedding-invitation">
                <div className="title">인사말</div>
                <div className="content">
                    <div>
                        <span>지</span>난 3년간의 시간 속, 매 순간 곁에 있어 준 한 사람과
                    </div>
                    <div>
                        <span>은</span>하수와 같이 찬란하게 빛날 미래를 함께하고자 합니다.
                    </div>
                    <br />
                    <div>
                        <span>한</span>평생 서로를 밝게 비춰주며
                    </div>
                    <div>
                        <span>별</span>처럼 영원토록 반짝이는 사랑을 하겠습니다.{' '}
                    </div>
                    <br />
                    <div>저희 두사람이 하나 되는 순간이 더욱 반짝일 수 있도록</div>
                    <div>많은 축복 부탁드리겠습니다.</div>
                </div>
            </div>
            {/* 서브사진 */}
            <div className="sub-wedding-photo">
                <div
                    className="wedding-photo"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/img_sub1.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                ></div>
            </div>
            {/* 혼주 */}
            <div className="wedding-family">
                <div className="family">
                    강석헌·김문점
                    <span className="relation">
                        <span>의</span> <span>아들</span>
                    </span>
                    한별
                </div>
                <div className="family">
                    현철원·이춘미
                    <span className="relation">
                        <span>의</span> <span className="daughter">딸</span>
                    </span>
                    지은
                </div>
            </div>
            {/* 연락하기 */}
            <div className="wedding-contact" onClick={openModal}>
                연락하기
            </div>
            {/* 캘린더 */}
            <div className="wedding-calendar">
                <div className="calendar">
                    <div className="calendar-day">2024.09.01</div>
                    <div className="calendar-time">일요일 오후 2시</div>
                    <Calendar />
                    <div className="calendar-countdown">
                        한별<span>♥</span>지은의 결혼식이
                        <div className="countdown">{remainTime.day}일</div> 남았습니다.
                    </div>
                </div>
            </div>
            <div className="wedding-gallery">
                {/* 갤러리 */}
                <div className="gallery-title">갤러리</div>
                {/* <MyGallery /> */}
                <MyGallery />
            </div>
            {/* 지도 */}
            <div className="wedding-map">
                <div className="map-title">오시는 길</div>
                <div className="map-header">
                    <div> 부산 동구 조방로14(범일동,동일타워)</div>
                    <div className="map-hall"> 디엘 웨딩홀 3F 마이아홀</div>
                    <div className="map-address"> [GS편의점 앞 "B코아 E/L홀" 표지판에서 엘리베이터 탑승]</div>
                </div>
                <Kakao width={'100%'} />
                {/* 링크 */}
                <Link />
                {/* 교통편 */}
                <div className="map-traffic">
                    <div className="traffic-title">지하철</div>
                    <div className="traffic-content">[1호선] 범일역 하차 - 2번 출구 이용 (도보 7분)</div>
                    <div className="traffic-content">[2호선] 문현역 하차 - 3번 출구 이용 (도보 5분)</div>
                    <div className="traffic-title">버스</div>
                    <div className="traffic-content">[부산진성공원] - 22, 26, 27, 40, 41, 42, 83, 108</div>
                    <div className="traffic-content">[시민회관] - 57, 68, 80, 83-1, 99, 134 </div>
                    <div className="traffic-content">[범일동 국민은행] - 10, 23, 108, 138 </div>
                    <div className="traffic-title">주차</div>
                    <div className="traffic-content">[동일타워] - 지하주차장 이용 가능</div>
                </div>
            </div>
            {/* 계좌번호 */}
            <div className="wedding-account">
                <div className="account-title">마음 전하실 곳</div>
                <div className="account-content">
                    참석이 어려우신 분들을 위해
                    <br /> 계좌 번호를 기재하였습니다.
                    <br /> 너그러운 마음으로 양해 부탁드립니다.
                </div>
                <Accordion />
            </div>
            {/* 축하메시지 */}
            <div className="wedding-guest-book">
                <div className="guest-book-title">방명록</div>
                <div className="guest-book-content">
                    <div>축하의 마음을 남겨주세요.</div>
                    <div>소중한 축하의 말 한마디가 큰 힘이 됩니다.</div>
                </div>
            </div>
            <div className="guest-book-form">
                <div className="guest-book-list">
                    {guestBooks.map((guestBook) => (
                        <div className="guest-book-item" key={guestBook.id}>
                            <div className="guest-book-inform">
                                <div className="guest-book-name">💚{guestBook.nickname}💚</div>
                                <div>
                                    <div className="guest-book-date">{getTimeAgo(guestBook.createdAt)}</div>
                                    <img
                                        onClick={() => deleteGuestBook(guestBook.id)}
                                        className="close-img"
                                        src={close}
                                    />
                                </div>
                            </div>
                            <div className="guest-book-message">{guestBook.content}</div>
                        </div>
                    ))}
                </div>
                <div className="guest-book-input">
                    <div className="input-inform">
                        <input
                            className="input-name"
                            placeholder="이름"
                            onChange={handleChange}
                            name="nickname"
                            maxLength={8}
                            value={guestBookInput.nickname}
                        ></input>
                        <input
                            className="input-password"
                            placeholder="비밀번호"
                            type="password"
                            onChange={handleChange}
                            name="password"
                            maxLength={8}
                            value={guestBookInput.password}
                        ></input>
                    </div>
                    <div className="input-submit">
                        <input
                            className="input-message"
                            placeholder="축하메시지를 입력해주세요."
                            onChange={handleChange}
                            name="content"
                            maxLength={80}
                            value={guestBookInput.content}
                        ></input>
                        <button className="input-button" onClick={addGuestBook}>
                            전송
                        </button>
                    </div>
                </div>
            </div>
            <div className="last-wedding-photo">
                <div
                    className="wedding-photo"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/img_sub.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                ></div>
            </div>
            <div className="footer">
                <KakaoButton />
            </div>
            <Modal modal={modal} setModal={setModal} />
        </div>
    );
}

export default App;
