import React, { useEffect } from 'react';
import './kakao.css';

const Kakao = ({ width, height }) => {
    useEffect(() => {
        if (document.getElementById('daumRoughmapScript')) return;
        // Daum 지도 API 스크립트 삽입
        const script = document.createElement('script');
        script.id = 'daumRoughmapScript';
        script.src = 'https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js';
        script.async = true;
        document.head.appendChild(script);

        // 지도초기화
        script.onload = () => {
            new window.daum.roughmap.Lander({
                timestamp: '1718779906733',
                key: '2jqdd',
                mapWidth: width,
                mapHeight: height,
            }).render();
        };
    }, [width, height]);

    return (
        <div
            id="daumRoughmapContainer1718779906733"
            className="root_daum_roughmap root_daum_roughmap_landing"
            style={{ width, height }}
        ></div>
    );
};

export default Kakao;
