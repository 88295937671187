import Accordion from 'react-bootstrap/Accordion';
import copy from './copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import './accordion.css';
import { useState } from 'react';

function Accodion() {
    return (
        <div>
            <Accordion>
                <AccordionItem title="신랑 측 계좌번호">
                    <AccountDetails
                        accounts={[
                            { number: null, holder: '강한별' },
                            { number: null, holder: '강석헌' },
                            { number: null, holder: '김문점' },
                        ]}
                    />
                </AccordionItem>
                <AccordionItem title="신부 측 계좌번호">
                    <AccountDetails
                        accounts={[
                            { number: '1002-261-542447', holder: '우리은행 현지은' },
                            { number: '089120719225', holder: '부산은행 현철원' },
                            { number: '102-009147-01-030', holder: '기업은행 이춘미' },
                        ]}
                    />
                </AccordionItem>
            </Accordion>
        </div>
    );
}

const Accordions = ({ children }) => {
    return <div className="accordion">{children}</div>;
};

const AccordionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-item">
            <div className="accordion-header" onClick={toggleAccordion}>
                {title}
            </div>
            {isOpen && <div className="accordion-body">{children}</div>}
        </div>
    );
};

const AccountDetails = ({ accounts }) => {
    return (
        <div>
            {accounts.map((account, index) => (
                <div className="account-container" key={index}>
                    <div>
                        <div className="account">{account.number}</div>
                        <div className="account-holder">{account.holder}</div>
                    </div>
                    {account.number !== null ? (
                        <CopyToClipboard text={account.number}>
                            <img className="copy-image" src={copy} alt="Copy" />
                        </CopyToClipboard>
                    ) : <div className='account-null'>계좌 미 기재</div>}
                </div>
            ))}
        </div>
    );
};

export default Accodion;
