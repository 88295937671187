import React from 'react';
import ImageGallery from 'react-image-gallery';
import './Gallery.css';

const images = [
    {
        original: `${process.env.PUBLIC_URL}/images/img_main.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/img_main.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_1.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_1.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_2.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_2.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_3.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_3.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_4.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_4.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_5.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_5.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_6.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_6.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_7.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_7.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_8.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_8.jpg`,
    },
    {
        original: `${process.env.PUBLIC_URL}/images/gallery_9.jpg`,
        thumbnail: `${process.env.PUBLIC_URL}/images/gallery_9.jpg`,
    },
];

export class MyGallery extends React.Component {
    render() {
        return <ImageGallery items={images} />;
    }
}
