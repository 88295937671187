import React, { useEffect } from 'react';
import styled from 'styled-components';

const { Kakao } = window;

const StyledKakaoButton = styled.div`
    .kakao-share-btn {
        color: #888;
        text-align: center;
        cursor: pointer;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .kakao-logo {
        width: 20px;
        margin-right: 5px;
    }
`;

const KakaoButton = () => {
    useEffect(() => {
        Kakao.cleanup();
        Kakao.init('012a80c41a61994f647484592369f661');
    }, []);

    const kakaoShare = () => {
        Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: '강한별💚현지은 결혼합니다.',
                description: '2024년 09월 01일 오후 2시',
                imageUrl: 'https://oracle-image.dev-arthur.com/mobile-wedding/kakao-share-jieun.jpg',
                link: {
                    mobileWebUrl: 'https://hb-je-weddingday.dev-arthur.com/', //이건 배포 주소
                    webUrl: 'https://hb-je-weddingday.dev-arthur.com/',
                },
            },
            // social: {
            //     likeCount: 286,
            //     commentCount: 45,
            //     sharedCount: 845,
            // },
            buttons: [
                {
                    title: '모바일 청첩장',
                    link: {
                        mobileWebUrl: 'https://hb-je-weddingday.dev-arthur.com/',
                        webUrl: 'https://hb-je-weddingday.dev-arthur.com/',
                    },
                },
                {
                    title: '위치 보기',
                    link: {
                        mobileWebUrl: 'https://kko.to/Bw9hT3MQCj',
                        webUrl: 'https://kko.to/Bw9hT3MQCj',
                    },
                },
            ],
        });
    };

    return (
        <StyledKakaoButton>
            <div className="kakao-share-btn" onClick={kakaoShare}>
                <img className="kakao-logo" src="/images/kakao.png" alt="kakao" />
                카카오 공유하기
            </div>
        </StyledKakaoButton>
    );
};

export default KakaoButton;
